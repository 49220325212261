import * as Fresco from '@staaky/fresco';
import Splide from '@splidejs/splide';

export function initLazyGal() {

    let gals = $(".lazyGalerieBlock");
    gals.each(function(i) {
        let gal = $(this);
        let elms = $(this).find(".lazyGalerieBlock__slide");
        let thumbs = $(this).find(".lazyGalerieBlock__thumbnail");
        let current; // Keeps the current thumbnail

        // pour chaque block gallerie
        elms.each(function(i) {
            // initialisation slider
            var splide = new Splide($(this)[0], {
                pagination: false,
                arrows: true,
                rewind: true,
                i18n: {
                    prev: 'Image précédente',
                    next: 'Image suivante',
                    first: 'Aller à la première image',
                    last: 'Aller à la dernière image',
                    slideX: 'Aller à l\'image %s'
                }
            });


            //initialisation thumbs
            if ($(this).hasClass('withThumbs')) {

                var splideThumbs = new Splide(gal.find(".lazyGalerieBlock__thumbnails__slider")[0], {
                    fixedWidth: 70,
                    fixedHeight: 70,
                    gap: 10,
                    rewind: true,
                    pagination: false,
                    arrows: false,
                    cover: true,
                    isNavigation: true,
                    i18n: {
                        prev: 'Image précédente',
                        next: 'Image suivante',
                        first: 'Aller à la première image',
                        last: 'Aller à la dernière image',
                        slideX: 'Aller à l\'image %s'
                    }
                });

            }

            if (splideThumbs) splide.sync(splideThumbs);
            splide.mount();
            if (splideThumbs) splideThumbs.mount();

        });
    });
}


export function initAccordion() {
    $(".js-accordionBlock").click(function(e) {
        let $trigger = $(this);
        let $content = $trigger.parent().find(".accordionBlock__content").first();

        // VERSION FERME OUVERTS
        /*
        if ($trigger.hasClass("active")) {
            $content.slideUp(200, function() {
                $trigger.attr("aria-expanded", true).removeClass("active");
                $(this).attr("aria-hidden", false);
            });
        } else {
            // on referme tout
            $(".js-accordionBlock").each(function(i) {
                closeAccordion($(this).parent().find(".accordionBlock__content"), $(this));
            });
            $content.slideDown(200, function() {
                $trigger.attr("aria-expanded", true).addClass("active");
                $(this).attr("aria-hidden", false);
            });
        }
        */

        $trigger.toggleClass("active").parent().find(".accordionBlock__content").first().slideToggle(200, function() {
            if ($trigger.hasClass("active")) {
                $trigger.attr("aria-expanded", true);
                $(this).attr("aria-hidden", false);
            } else {
                $trigger.attr("aria-expanded", false);
                $(this).attr("aria-hidden", true);
            }
        });
        return false;
    });

    // ouvrir le premier accordion
    if ($(".section__blocks").hasClass("openFirst"))
        $(".js-accordionBlock").first().trigger("click");
}

export function initAccordionSpectacles() {
    $(".js-SpectaclesMinBlock").click(function(e) {
        let $trigger = $(this);
        $trigger.toggleClass("active").parent().find(".SpectaclesMinBlock__content").first().slideToggle(200, function() {
            if ($trigger.hasClass("active")) {
                $trigger.attr("aria-expanded", true);
                $(this).attr("aria-hidden", false);
            } else {
                $trigger.attr("aria-expanded", false);
                $(this).attr("aria-hidden", true);
            }
        });
        return false;
    });
}


function closeAccordion(item, button) {
    item.slideUp(200, function() {
        button.attr("aria-expanded", false).removeClass("active");
        $(this).attr("aria-hidden", true);
    });
}