import "es6-shim";
import anime from 'animejs/lib/anime.es.js';
import * as Fresco from '@staaky/fresco';
import Splide from '@splidejs/splide';
import device from "current-device";
import MicroModal from 'micromodal';
import Masonry from 'masonry-layout';
import overlayScrollbars from 'overlayScrollbars/js/jquery.overlayScrollbars.min.js';

import { ready } from './_ready.js';
import * as Nav from "./_nav.js";
import * as Blocks from "./_blocks.js";
import * as Actus from "./_actus.js";

ready(function() {

    var ieVer = ieVersion();

    if (ieVer > 0) {
        $("html").addClass("ie ie-" + ieVer);
    }




    /********************/
    /********************/
    /********************/
    /********************/
    // ACTUS

    if ($(".actusList ").length > 0) {

        var msnry = new Masonry('.actusList', {
            itemSelector: '.grid-item',
            columnWidth: '.grid-sizer',
            percentPosition: true,
            horizontalOrder: true,
            transitionDuration: '0,1s'
        });

        Actus.initActusSplide();
        msnry.layout();
    }

    Actus.initToogleTrigger(msnry);

    let sT = "never";
    if (device.desktop()) sT = "leave";

    let listeActus = $(".actusWrapper").overlayScrollbars({
        className: "os-theme-thin-dark os-aurore",
        paddingAbsolute: true,
        scrollbars: {
            autoHide: sT,
        }
    });


    $('.js-ActusLoadMore').click(function() {
        var button = $(this),
            data = {
                'action': 'loadmore',
                'query': misha_loadmore_params.posts, // that's how we get params from wp_localize_script() function
                'page': misha_loadmore_params.current_page,
                'security': misha_loadmore_params.security
            };

        $.ajax({ // you can also use $.post here
            url: misha_loadmore_params.ajaxurl, // AJAX handler
            data: data,
            type: 'POST',
            beforeSend: function(xhr) {
                button.text('Chargement...'); // change the button text, you can also add a preloader image
            },
            success: function(data) {


                if (data) {
                    button.text('Publications précédentes');
                    //$(".js-actusListToAdd").append(data);
                    var elems = [];
                    var fragment = document.createDocumentFragment();
                    $(data).each(function(index) {
                        var elem = $(this)[0];
                        fragment.appendChild(elem);
                        elems.push(elem);
                    });

                    $(".js-actusListToAdd").append(fragment);
                    msnry.appended(elems);


                    misha_loadmore_params.current_page++;
                    if (misha_loadmore_params.current_page == misha_loadmore_params.max_page)
                        button.remove(); // if last page, remove the button

                    $(document.body).trigger('ajax-actus-load');

                    // you can also fire the "post-load" event here if you use a plugin that requires it
                    // $( document.body ).trigger( 'post-load' );
                } else {
                    button.remove(); // if no data, remove the button as well
                }
            }
        });
    });

    $(document.body).on("ajax-actus-load", function() {
        msnry.layout();
        Actus.initToogleTrigger(msnry);
        Actus.initActusSplide();

        //if (!$(".actusWrapper").hasClass("added")) $(".actusWrapper").addClass("added");

        setTimeout(function() { msnry.layout(); }, 500);
    });



    /********************/
    /********************/
    /********************/
    /********************/
    //NAV

    Nav.initNav();

    /*END NAV***************/
    /********************/
    /********************/
    /********************/


    //*********************/
    //*********************/
    //*********************/
    //*********************/
    // BLOCKS

    Blocks.initLazyGal();
    Blocks.initAccordion();
    Blocks.initAccordionSpectacles();


    //FIN BLOCKS


    //*********************/
    //*********************/
    //*********************/
    //*********************/
    // SPECTACLES

    if ($(".js-openOld").length > 0) {
        MicroModal.init({
            disableFocus: true,
            disableScroll: true,
            awaitOpenAnimation: true,
            awaitCloseAnimation: true,
            onClose: modal => {
                $('body').removeClass("modalOpen");
            },
            onShow: modal => {
                $('body').addClass("modalOpen");

            }
        });
    }

    let modalSpec = $(".modal__content").overlayScrollbars({ className: "os-theme-dark os-aurore", paddingAbsolute: true });
    let colSpec = $(".col__calendarSpec").overlayScrollbars({ className: "os-theme-thin-dark os-aurore", paddingAbsolute: true });






    /********************/
    /********************/
    // NEWSLETTER
    mc4wp.forms.on('success', function(form) {
        setTimeout(function() {
            anime({
                targets: '.mc4wp-alert',
                opacity: 0,
                translateY: ['0', '-10px'],
                duration: 500,
                easing: 'cubicBezier(.215,.61,.355,1)',
                complete: function(anim) {
                    $(".mc4wp-alert").hide();
                }
            });

        }, 2000);
    });

    mc4wp.forms.on('error', function(form) {
        setTimeout(function() {
            anime({
                targets: '.mc4wp-alert',
                opacity: 0,
                translateY: ['0', '-10px'],
                duration: 500,
                easing: 'cubicBezier(.215,.61,.355,1)',
                complete: function(anim) {
                    $(".mc4wp-alert").hide();
                }
            });
        }, 2000);
    });


});

function ieVersion(uaString) {
    uaString = uaString || navigator.userAgent;
    var match = /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(uaString);
    if (match) return parseInt(match[2])
}