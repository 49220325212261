import * as Fresco from '@staaky/fresco';
import Splide from '@splidejs/splide';

export function initActusSplide() {
    var elms = document.getElementsByClassName('actu__splide');
    for (var i = 0; i < elms.length; i++) {
        new Splide(elms[i], {
            pagination: false,
            arrows: true,
            rewind: true,
            i18n: {
                prev: 'Image précédente',
                next: 'Image suivante',
                first: 'Aller à la première image',
                last: 'Aller à la dernière image',
                slideX: 'Aller à l\'image %s'
            }
        }).mount();
    }
}

export function  initToogleTrigger( msnry ) {
    $(".toggle-trigger").click(function() {
        let $trigger = $(this);
        $trigger.toggleClass("active").prev().slideToggle(200, function() {
            if ($trigger.hasClass("active")) {
                $trigger.text("Afficher moins").attr("aria-expanded", true);

            } else {
                $trigger.text($trigger.data("text")).attr("aria-expanded", false);;
            }
            msnry.layout();
        });
        return false;
    });
}